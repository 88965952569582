import React from "react";
import ana from "./components/sliki.png/anafit.png";
import { FaFacebookF } from "react-icons/fa";
import { MdPhoneInTalk } from "react-icons/md";
import { FiInstagram } from "react-icons/fi";
import { MdSubscriptions } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { FaLinkedin } from "react-icons/fa";
import {
  Box,
  Container,
  Row,
  Column,
  FooterLink,
  Heading,
} from "./components/FooterStyles.js";
export default function Footer() {
  const { t } = useTranslation();
  return (
    <Box
      style={{
        backgroundColor: "#fbe6fc",
        paddingTop: "5px",
        display: "absolute",
      }}
    >
      <Container>
        <Row>
          <Column>
            <Heading>
              <img src={ana} alt={ana} style={{ width: "150px" }} />
            </Heading>
          </Column>
          <Column>
            <Heading>
              <FooterLink href="/zanas" style={{ color: "purple" }}>
                {t("footer.1")}
              </FooterLink>{" "}
            </Heading>
          </Column>

          <Column>
            <Heading>
              {" "}
              <FooterLink href="/faq" style={{ color: "purple" }}>
                {t("footer.2")}
              </FooterLink>{" "}
            </Heading>{" "}
          </Column>
          <Column>
            <Heading>
              {" "}
              <FooterLink href="/blog" style={{ color: "purple" }}>
                {t("footer.3")}
              </FooterLink>{" "}
            </Heading>
          </Column>
          <Column>
            <Heading style={{ color: "black" }}>{t("footer.4")}</Heading>
            <FooterLink href="https://www.facebook.com/profile.php?id=100063726364480">
              <FaFacebookF style={{ color: "#7732b3" }} />
              <span style={{ marginLeft: "10px", color: "purple" }}>
                Facebook
              </span>
            </FooterLink>
            <FooterLink href="https://www.instagram.com/anafit.mk/">
              <FiInstagram style={{ color: "#7732b3" }} />
              <span style={{ marginLeft: "10px", color: "purple" }}>
                Instagram
              </span>
            </FooterLink>

            <FooterLink href="https://www.linkedin.com/in/aleksandra-orovchanec-37a989218/">
              <FaLinkedin style={{ color: "#7732b3" }} />
              <span style={{ marginLeft: "10px", color: "purple" }}>
                LinkedIn
              </span>
            </FooterLink>

            <FooterLink href="https://www.youtube.com/channel/UCGCKBZgNzXnrFwu3indVy6g">
              <MdSubscriptions style={{ color: "#7732b3" }} />
              <span style={{ marginLeft: "10px", color: "purple" }}>
                Youtube
              </span>
            </FooterLink>
            <p style={{ color: "black" }}>
              {" "}
              <MdPhoneInTalk style={{ color: "black" }} /> &nbsp; +38972-315-622{" "}
            </p>
          </Column>
        </Row>
        <Row
          style={{
            // paddingLeft: "250px",
            paddingTop: "20px",
            color: "black",
            paddingBottom: "0",
            maxWidth: "100%",
            maxHeight: "100%",
          }}
        >
          <small>
            {" "}
            ANA FIT © 2021 | All Rights Reserved | 100% safe for use!
          </small>
        </Row>
      </Container>
    </Box>
  );
}
